<template>
  <div id="app">

    <router-view/>
  </div>
</template>

<script>
	export default {
	
	  watch:{
	
	   $route() {
	         // 路由变化的时候，让滚动数据变为0
	         document.body.scrollTop = 0
	         document.documentElement.scrollTop = 0
	       },
	
	   }
	
	  
	
	 }
</script>
<style>
	body{
		font: 14px/1.75 -apple-system,"Helvetica Neue",Helvetica,Arial,sans-serif;
	}	
body,html{
	min-width: 1280px;
	/* font-size: 73.6667px; */
	margin: 0;
	color: rgba(0,0,0,.65);
}
p{
	margin: 0;
}

</style>
