import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
	{
	  path: '/index',
	  name: 'index',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
	},
	{
	  path: '/about/presidentWords',
	  name: 'presidentWords',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/presidentWords.vue')
	},
	{
	  path: '/about/introduction',
	  name: 'introduction',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/introduction.vue')
	},
	{
	  path: '/about/visitCampus',
	  name: 'visitCampus',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/visitCampus.vue')
	},
	{
	  path: '/about/architectureAndTeam',
	  name: 'architectureAndTeam',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/architectureAndTeam.vue')
	},
	{
	  path: '/about/co',
	  name: 'co',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/co.vue')
	},
	{
	  path: '/teaching/sixCollegesList',
	  name: 'sixCollegesList',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/sixCollegesList.vue')
	},
	{
	  path: '/teaching/sixColleges',
	  name: 'sixColleges',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/sixColleges.vue')
	},
	{
	  path: '/teaching/commercial',
	  name: 'commercial',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/commercial.vue')
	},
	{
	  path: '/teaching/igandAlevel',
	  name: 'igandAlevel',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/igandAlevel.vue')
	},
	{
	  path: '/teaching/btec',
	  name: 'btec',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/btec.vue')
	},
	{
	  path: '/life/campusNews',
	  name: 'campusNews',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/campusNews.vue')
	},
	{
	  path: '/life/TVstation',
	  name: 'TVstation',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/TVstation.vue')
	},
	{
	  path: '/life/calendar',
	  name: 'calendar',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/calendar.vue')
	},
	{
	  path: '/life/TVPlayer',
	  name: 'TVPlayer',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/TVPlayer.vue')
	},
	{
	  path: '/planning/service',
	  name: 'service',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/service.vue')
	},
	{
	  path: '/planning/College',
	  name: 'College',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/College.vue')
	},
	{
	  path: '/planning/CollegeInside',
	  name: 'CollegeInside',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/CollegeInside.vue')
	},
	{
	  path: '/planning/foreignCommercial',
	  name: 'foreignCommercial',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/foreignCommercial.vue')
	},
	{
	  path: '/information/story',
	  name: 'story',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/story.vue')
	},
	{
	  path: '/information/rules',
	  name: 'rules',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/rules.vue')
	},
	{
	  path: '/information/scholarship',
	  name: 'scholarship',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/scholarship.vue')
	},
	{
	  path: '/information/qanda',
	  name: 'qanda',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/qanda.vue')
	},
	{
	  path: '/information/apply',
	  name: 'apply',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/apply.vue')
	},
	{
	  path: '/information/message',
	  name: 'message',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/message.vue')
	},
	{
	  path: '/contact/Consultation',
	  name: 'Consultation',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/contact/Consultation.vue')
	},
	{
	  path: '/contact/recruit',
	  name: 'recruit',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/contact/recruit.vue')
	},
	{
	  path: '/indexCN',
	  name: 'indexCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/indexCN/index.vue')
	},
	{
	  path: '/aboutCN/presidentWords',
	  name: 'presidentWordsCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/aboutCN/presidentWords.vue')
	},
	{
	  path: '/aboutCN/introduction',
	  name: 'introductionCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/aboutCN/introduction.vue')
	},
	{
	  path: '/aboutCN/visitCampus',
	  name: 'visitCampusCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/aboutCN/visitCampus.vue')
	},
	{
	  path: '/aboutCN/architectureAndTeam',
	  name: 'architectureAndTeamCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/aboutCN/architectureAndTeam.vue')
	},
	{
	  path: '/aboutCN/co',
	  name: 'coCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/aboutCN/co.vue')
	},
	{
	  path: '/teachingCN/sixCollegesList',
	  name: 'sixCollegesListCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teachingCN/sixCollegesList.vue')
	},
	{
	  path: '/teachingCN/sixColleges',
	  name: 'sixCollegesCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teachingCN/sixColleges.vue')
	},
	{
	  path: '/teachingCN/commercial',
	  name: 'commercialCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teachingCN/commercial.vue')
	},
	{
	  path: '/teachingCN/igandAlevel',
	  name: 'igandAlevelCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teachingCN/igandAlevel.vue')
	},
	{
	  path: '/teachingCN/btec',
	  name: 'btecCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teachingCN/btec.vue')
	},
	{
	  path: '/lifeCN/campusNews',
	  name: 'campusNewsCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/lifeCN/campusNews.vue')
	},
	{
	  path: '/lifeCN/TVstation',
	  name: 'TVstationCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/lifeCN/TVstation.vue')
	},
	{
	  path: '/lifeCN/calendar',
	  name: 'calendarCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/lifeCN/calendar.vue')
	},
	{
	  path: '/lifeCN/TVPlayer',
	  name: 'TVPlayerCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/lifeCN/TVPlayer.vue')
	},
	{
	  path: '/planningCN/service',
	  name: 'serviceCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planningCN/service.vue')
	},
	{
	  path: '/planningCN/College',
	  name: 'CollegeCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planningCN/College.vue')
	},
	{
	  path: '/planningCN/CollegeInside',
	  name: 'CollegeInsideCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planningCN/CollegeInside.vue')
	},
	{
	  path: '/planningCN/foreignCommercial',
	  name: 'foreignCommercialCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planningCN/foreignCommercial.vue')
	},
	{
	  path: '/informationCN/story',
	  name: 'storyCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/informationCN/story.vue')
	},
	{
	  path: '/informationCN/rules',
	  name: 'rulesCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/informationCN/rules.vue')
	},
	{
	  path: '/informationCN/scholarship',
	  name: 'scholarshipCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/informationCN/scholarship.vue')
	},
	{
	  path: '/informationCN/qanda',
	  name: 'qandaCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/informationCN/qanda.vue')
	},
	{
	  path: '/informationCN/apply',
	  name: 'applyCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/informationCN/apply.vue')
	},
	{
	  path: '/informationCN/message',
	  name: 'messageCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/informationCN/message.vue')
	},
	{
	  path: '/contactCN/Consultation',
	  name: 'ConsultationCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/contactCN/Consultation.vue')
	},
	{
	  path: '/contactCN/recruit',
	  name: 'recruitCN',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/contactCN/recruit.vue')
	}
]

const router = new VueRouter({
  routes
})

export default router
